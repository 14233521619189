.line{
    width: 100%;
    height: 2px;
    background-color: #1E1E1E;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

.tab_contact{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.title_tab_contact{
    font-size: 24px;
    font-weight: 500;
}

.desc_tab_contact{
    margin-left: auto;
    display: flex;
    flex-direction: row;
}

.icons_tab_contact{
    margin: 0 1vh;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desc_tab_contact span{
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

@media screen and (max-width: 460px) {
    .tab_contact{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .desc_tab_contact{
        margin: 0;
    }
}