.landing_page{
    background-color: #1E1E1E ;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.main{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}