.container_timeline{
    position: relative;
    width: 350px;
    height: 100px;
    padding: 0vh 2vh;
    display: flex;
    flex-direction: column;
    font-weight: 400;
}

.title_timeline{
    width: fit-content;
    color: #FFFFFF;
    font-weight: 500;
    cursor: pointer;
}

.description_timeline{
    color: #FFFFFF;
    font-weight: 450;
    cursor: pointer;
    font-size: 17px;
}

.left_container{
    margin-right: auto;
    text-align: right;
}

.right_container{
    margin-left: auto;
}

.left_container .title_timeline,
.left_container .description_timeline{
    margin-left: auto;
}
.right_container .title_timeline,
.right_container .description_timeline{
    margin-right: auto;
}

.bubble{
    position: absolute;
    z-index: 2;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #1E1E1E;
    transition: 1s all ease-in-out;
}

.bubble_left {
    right: -7.5px; /* Adjust this value to center, it's half the size of the bubble */
    top: 0;
    transform: translateY(-50%); /* Correct vertical alignment */
}

.bubble_right {
    left: -7.5px; /* Adjust this value to center, it's half the size of the bubble */
    top: 0;
    transform: translateY(-50%); /* Correct vertical alignment */
}

.title_timeline:hover ~ .bubble,
.description_timeline:hover ~ .bubble{
    scale: 1.2;
    background-color: #FFFFFF;
}

@media screen and (max-width: 785px) {
    .container_timeline{
        width: 200px;
    }
}

@media screen and (max-width: 405px) {
    .container_timeline{
        font-size: 1.5vh;
    }

    .description_timeline{
        font-size: 1.7vh;
    }

    .container_timeline{
        width: 50%;
    }
}