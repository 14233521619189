.navbar{
    z-index: 99;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2vh 3vh;
    background-color: #1E1E1E;
    transition: 0.5s all ease-in-out;
}

.navitems{
    margin-left: auto;
}

.navbarScroll{
    visibility: hidden;
    opacity: 0;
    transition: 0.5s all ease-in-out;
}